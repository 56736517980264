var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SvgMap", {
    attrs: { map: _vm.World, isLocationSelected: _vm.checkLocationSelected },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }